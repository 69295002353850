<template>
  <div>
    <div class="bgStyle">
      <div class="title">项目模板</div>
      <div>
        <a-space size="8">
          <a-input
            v-model="filter"
            placeholder="模板名称"
            @keyup.enter="getProjectTemplate"
            style="width: 200px"
          />
          <a-button type="primary" @click="getProjectTemplate">查询 </a-button>
        </a-space>
      </div>
    </div>
    <div class="bgStyles">
      <a-spin :spinning="loading">
        <a-space size="8">
          <a-list
            :grid="{ gutter: 48, column: 4 }"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            style="background: #ececec; width: 1660px"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :title="item.title" style="width: 380px">
                <div slot="extra" v-if="item.isMyTemplate" class="box">
                  <p>我的模板</p>
                </div>
                <div slot="extra" v-else class="boxblue">
                  <p>公共模板</p>
                </div>
                <div @click="listclick(item)" class="templetList">
                  <a-row :gutter="16">
                    <a-col>
                      <a-tooltip placement="right" :title="item.description">
                        <p class="p"><span class="tit">模板描述</span>：{{ itemDescription(item) }}</p>
                      </a-tooltip>
                    </a-col>
                  </a-row>
                  <a-row :gutter="16">
                    <a-col :span="12">
                      <p class="p"><span class="tit">发布人</span>：{{ item.publisherName }}</p>
                    </a-col>
                    <a-col :span="12">
                      <p class="p"><span class="tit">模板类型</span>:{{ item.type == 1 ? "公开" : "不公开" }}</p>
                    </a-col>
                  </a-row>
                  <p class="p">
                    <span class="tit">发布时间：</span>{{ moment(item.creationTime).format(dateFormat) }}
                  </p>
                </div>
                <template slot="actions" class="ant-card-actions">
                  <div>
                    <a-icon key="copy" type="copy" />{{ item.quoteNumber }}
                  </div>
                  <div>
                    <a-icon
                      v-if="item.isCollection"
                      key="star"
                      @click="updateCollectionNumber(item)"
                      type="star"
                      theme="twoTone"
                      two-tone-color="#ffc53d"
                    />
                    <a-icon
                      v-else
                      key="star"
                      @click="updateCollectionNumber(item)"
                      type="star"
                    />{{ item.collectionNumber }}
                  </div>
                  <a-icon
                    v-if="item.isMyTemplate"
                    type="delete"
                    @click="deleteTemplate(item)"
                    style="float: right; font-size: 24px"
                  />
                  <a-icon
                    v-else
                    type="delete"
                    style="float: right; font-size: 24px; color: #fafafa"
                  />
                </template>
              </a-card>
            </a-list-item>
          </a-list>
        </a-space>
      </a-spin>
    </div>
  </div>
</template>

<script>
import {
  getProjectTemplateList,
  deleteProjectTemplate,
  updateProjectTemplateCollectionNumber,
} from "/src/api/projecttemplate.js";
import moment from "moment";
export default {
  data() {
    return {
      data: [],
      dateFormat: "YYYY-MM-DD",
      filter: "", //项目模板查询条件
      loading: false,
      creatorId: this.$store.getters.getUserInfo.id, //当前用户ID todo:当前用户未做缓存 store.getters.getUserInfo
      isCancel: false, //是否为我的收藏
      pagination: {
        /**
         * 列表页面切换事件
         * @param {int} page [当前页数]
         */
        onChange: (page) => {
          this.pagination.page = page;
          this.getProjectTemplateList();
        },
        pageSize: 8, //每页显示项数
        page: 1, //默认页数
        showSizeChanger: true, //是否能更换页码
        pageSizeOptions: ["8", "16", "24", "32", "40"], //每页显示页码数
        /**
         * 每页页码变更事件
         * @param {int} current [当前页数]
         * @param {int} size [当前页码]
         */
        onShowSizeChange: (current, size) => {
          this.pagination.page = current;
          this.pagination.pageSize = size;
          this.getProjectTemplateList();
        },
      },
    };
  },
  /** 组件生命周期编译后事件 */
  mounted() {
    this.getProjectTemplateList();
  },
  methods: {
    moment,
    /**
     * 跳转模板详细事件
     * @param {obj} item 项目模板数据对象
     */
    listclick(item) {
      this.$router.push({
        name: "TemplateDetails",
        params: item,
      });
    },
    /**
     * 显示模板描述
     * @param {object} item 实体对象
     */
    itemDescription(item) {
      if (item.description && item.description.length > 35)
        return item.description.substring(0, 35) + "...";
      return item.description;
    },
    /**
    /**
     * 删除项目模板
     * @param {obj} item 项目模板数据对象
     */
    deleteTemplate(item) {
      this.loading = true;
      deleteProjectTemplate(item.id)
        .then(() => {
          this.loading = false;
          this.$message.success("删除成功");
          this.getProjectTemplateList();
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("删除失败");
        });
    },
    /**
     * 查询项目模板
     * @param {int} page 查询页数
     */
    getProjectTemplateList(page) {
      this.loading = true;
      page = !page ? this.pagination.page - 1 : page;
      var parms = {
        skipCount: page * this.pagination.pageSize,
        maxResultCount: this.pagination.pageSize,
        filter: this.filter,
      };
      getProjectTemplateList(parms).then((res) => {
        this.loading = false;
        this.data = res.items;
        this.pagination.total = res.totalCount;
      });
    },
    /**
     * 项目模板查询输入框回车事件
     */
    getProjectTemplate() {
      this.getProjectTemplateList(0);
    },
    /**
     * 项目模板收藏事件
     * @param {obj} item 项目模板数据对象
     */
    updateCollectionNumber(item) {
      var parms = {
        ProjectTemplateId: item.id,
        isCancel: item.isCollection,
      };
      this.loading = true;
      updateProjectTemplateCollectionNumber(parms).then(() => {
        this.loading = false;
        item.isCollection = !item.isCollection;
        item.collectionNumber = item.isCollection
          ? (item.collectionNumber += 1)
          : (item.collectionNumber -= 1);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 12px;
}
.bgStyles {
  margin: 5px 10px;
  padding: 10px 15px;
  ::v-deep .ant-card-head {
    padding: 0;
  }
  ::v-deep .ant-card-extra {
    padding: 0;
  }
  ::v-deep.ant-card-head-title {
    padding: 16px 10px;
  }
}
.box {
  padding: 0px;
  width: 0px;
  height: 0px;
  border-top: 0px solid transparent;
  border-right: 80px solid rgb(255, 153, 0);
  border-bottom: 80px solid transparent;
  border-left: 0px solid transparent;
  transform: rotate(0deg);
}
.box p {
  width: max-content;
  position: absolute;
  color: rgb(255, 255, 255);
  transform: rotate(45deg) translateY(-10px) translateX(30px);
}
.boxblue {
  padding: 0px;
  width: 0px;
  height: 0px;
  border-top: 0px solid transparent;
  border-right: 80px solid #1890ff;
  border-bottom: 80px solid transparent;
  border-left: 0px solid transparent;
  transform: rotate(0deg);
}
.boxblue p {
  width: max-content;
  position: absolute;
  color: rgb(255, 255, 255);
  transform: rotate(45deg) translateY(-10px) translateX(30px);
}

.templetList p{
  margin-bottom: 10px;
}

.templetList p span{
  font-weight: bold;
}
.tit{
  font-weight: bold;
}

.p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 26px;
}
</style>
