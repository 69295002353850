/* eslint-disable no-unused-vars */
import axios from "axios";
import qs from "qs";
import store from "@/store";
import notification from "ant-design-vue/es/notification";
// const url =  process.env.NODE_ENV === "development" ? "/api" : process.env.VUE_APP_API_BASE_URL;
const url = process.env.VUE_APP_API_BASE_URL;
// 创建 axios 实例
// 请求时带入Cookies
// axios.defaults.withCredentials = true;
const request = axios.create({
  // API 请求的默认前缀
  baseURL: `${url}/api`,
  timeout: 30000, // 请求超时时间
});
const errorHandler = (error) => {
  const errorRes = error.response;
  if (errorRes) {
    // 从 localstorage 获取 token
    let token = store.getters.getToken;
    if (token && token.access_token) {
      token = token.access_token;
    }
    if (errorRes.headers._abperrorformat && errorRes.status === 401) {
      store.dispatch("logout", true);
    }
    const err = getError(errorRes.data.error || {}, errorRes.status);
    notification.error({
      message: err.title,
      description: err.message,
    });
  } else {
    notification.error({
      message: (error || "An unexpected error has occurred").toString(),
    });
  }

  return Promise.reject(error);
};
function getError(error = {}, status) {
  let message = "";
  let title = "发生错误";
  if (typeof error === "string") {
    message = error;
  } else if (error.details) {
    message = error.details;
    title = error.message;
  } else if (error.message) {
    message = error.message;
  } else {
    switch (status) {
      case 401:
        title = "未登录";
        message = "您还没有登录到系统";
        break;
      case 403:
        title = "未授权";
        message = "您还没有对应的操作权限";
        break;
      case 404:
        title = "未找到";
        message = "资源不存在";
        break;
      case 500:
        title = "内部错误";
        message = "系统发生了内部错误";
        break;
      default:
        break;
    }
  }
  return { title, message };
}
// request interceptor
request.interceptors.request.use(async (request) => {
  const token = store.getters.getToken;
  const userInfo = store.getters.getUserInfo;
  const language = store.getters.getLanguage;
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (!request.headers.Authorization && token && token !== "1") {
    request.headers.Authorization = `Bearer ${token}`;
  }
  if (!request.headers["Content-Type"]) {
    request.headers["Content-Type"] = "application/json";
  }
  if (!request.headers["Accept-Language"] && language) {
    request.headers["Accept-Language"] = language;
  }
  // if (!request.headers.c_c && userInfo && userInfo.companyId) {
  //   request.headers.c_c = userInfo.companyId;
  // }
  if (!request.headers.__tenant && userInfo && userInfo.tenantId) {
    request.headers.__tenant = userInfo.tenantId;
  }
  const sessionId = store.getters.getSessionId;
  if (!request.headers.c_s && sessionId) {
    request.headers.c_s = sessionId;
  }
  return request;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  if (response.headers) {
    if (response.headers["c_s"]) {
      store.commit("SET_SESSION_ID", response.headers["c_s"]);
    }
    // 刷新令牌
    if (response.headers["token"]) {
      store.commit("SET_TOKEN", { access_token: response.headers["token"] });
    }
  }
  return response.data;
}, errorHandler);

/**
 * 发起 Http Get 请求
 * @param {String} url 请求地址
 * @param {Object} data 请求数据
 * @returns Promise
 */
export const getAsync = (url, data) => {
  return request("get", url, data);
};

export function deleteRequest(apiUrl, parameter) {
  return request({
    url: apiUrl,
    params: parameter,
    paramsSerializer: function (params) {
      return qs.stringify(params, { indices: false });
    },
    method: "delete",
  });
}

export function getRequest(apiUrl, parameter) {
  return request({
    url: apiUrl,
    method: "get",
    params: parameter,
    paramsSerializer: (params) => qs.stringify(params, { indices: false }),
  });
}

export function postRequest(apiUrl, parameter, headers) {
  const config = {
    url: apiUrl,
    method: "post",
    data: parameter,
  };
  if (headers) {
    config.headers = headers;
  }
  return request(config);
}

export function putRequest(apiUrl, parameter) {
  return request({
    url: apiUrl,
    method: "put",
    data: parameter,
  });
}

export function updateRequest(apiUrl, parameter) {
  return putRequest(apiUrl, parameter);
}
