/* eslint-disable prettier/prettier */
import {
  postRequest,
  updateRequest,
  deleteRequest,
  getRequest,
} from "@/utils/http";
/** 查询项目模板集合 */
export const getProjectTemplateList = (parm) =>
  getRequest(`/common/projecttemplate/getList`, parm);
/**查询单条项目模板 */
export const getProjectTemplate = (id) =>
  getRequest(`/common/projecttemplate/${id}`);
/** 新建项目模板 */
export const createProjectTemplate = (parm) =>
  postRequest(`/common/projecttemplate/create`, parm);
/** 删除项目模板 */
export const deleteProjectTemplate = (id) =>
  deleteRequest(`/common/projecttemplate/${id}`);
/** 修改项目模板 */
export const updateProjectTemplate = (id, parm) =>
  updateRequest(`/common/projecttemplate/update/${id}`, parm);
/** 修改项目模板收藏次数 */
export const updateProjectTemplateCollectionNumber = (parm) =>
  postRequest(`/common/projecttemplate/collectionnumber`, parm);
